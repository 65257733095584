<template>
  <v-container fluid>
    <!--  <v-form ref="form" lazy-validation>
      <v-progress-linear v-if="this.tmplLoading" indeterminate />
      <v-row class="pl-4 pr-4">
        <v-col cols="12">
          <div
            v-if="this.headerJsonerror && form.tmplHeaderObj"
            class="text-danger"
          >
            {{ this.headerJsonerror }}
          </div>
          <div v-if="!this.headerJsonerror" class="text-success">
            Valid JSON ✔
          </div>
          <v-textarea
            id="headerJsonText"
            v-model="form.tmplHeaderObj"
            :rules="[(v) => !!v && prettyFormatHeader]"
            dense
            label="Header"
            outlined
            rows="10"
            @change="prettyFormatHeader"
            @focus="showTouchKeyboard"
          ></v-textarea>
        </v-col>

        <v-col cols="12">
          <div
            v-if="this.footerJsonerror && form.tmplFooterObj"
            class="text-danger"
          >
            {{ this.footerJsonerror }}
          </div>
          <div v-if="!this.footerJsonerror" class="text-success">
            Valid JSON ✔
          </div>
          <v-textarea
            id="footerJsonText"
            v-model="form.tmplFooterObj"
            :data-layout="KEYBOARD.KEYSETS.NORMAL"
            :rules="[(v) => !!v && prettyFormatFooter]"
            dense
            label="footer"
            outlined
            rows="10"
            @focus="showTouchKeyboard"
          ></v-textarea>
        </v-col>

        <v-col class="pa-0" cols="12">
          <v-divider class="pa-0 ma-0" />
        </v-col>
        <v-col class="text-right pt-0 pb-0" cols="12">
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="primary"
            @click="save"
          >
            {{ this.$t('generic.lang_save') }}
          </v-btn>
        </v-col>
        <v-col class="pa-0">
          <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard
              v-if="touchKeyboard.visible"
              id="onScreenKeyboard"
              :accept="hideTouchKeyboard"
              :cancel="hideTouchKeyboard"
              :defaultKeySet="touchKeyboard.keySet"
              :input="touchKeyboard.input"
              :layout="touchKeyboard.layout"
              :options="touchKeyboard.options"
              class="internalWidth"
            />
          </div>
        </v-col>
      </v-row>
    </v-form> -->

    <v-row>
      <v-col
        class="pa-2"
        cols="12"
        md="3"
      >
        <div class="models-panel border rounded">
          <div
            v-for="(group, i) in modelGroups"
            :key="i"
            class="transparent px-5"
          >
            <v-card-title class="px-0 text-capitalize py-1">
              {{ $t(group) }}
            </v-card-title>
            <span
              class="ma-2 my-2 drag-el"
              :transferData="item"
              v-for="item in modelsByGroup(group)"
              :key="item.title"
              draggable
              @dragstart="startDrag($event, item)"
            >
              <v-btn
                class="model-panel-element py-6"
                outlined
                color="primary"
              >
                <i
                  class="btn-icon-wrapper pb-1"
                  style="display: block !important"
                >
                  <v-icon>{{ item.icon }}</v-icon> </i
                ><span class="text-uppercase ml-2">{{ $t(item.title) }}</span>
              </v-btn>
            </span>
          </div>

          <v-card-actions class="border-top text-center">
            <v-btn
              :disabled="loading"
              :loading="loading"
              color="error"
              outlined
              depressed
              @click="cancel"
            >
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              {{ $t('generic.lang_prev') }}
            </v-btn>
            <v-spacer tag="div"></v-spacer>
            <v-btn
              :disabled="loading"
              :loading="loading"
              color="primary"
              depressed
              @click="save"
            >
              <v-icon class="mr-2">mdi-content-save</v-icon>
              {{ $t('generic.lang_save') }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="9"
        class="border-right"
      >
        <b-tabs v-model="currentTab" :active-nav-item-class="[$vuetify.theme.dark ? 'dark-bg' : '']">
          <b-tab
            active
            :title="$t('settings.lang_receiptSetHeader')"
          >
            <v-row>
              <v-col cols="8">
                <div
                  :class="[$vuetify.theme.dark ? 'dark-bg' : 'grey lighten-4']"
                  class="drop-zone rounded pa-3"
                  @drop="onDrop($event, 'header')"
                  @dragover="onDragOver($event, 'header')"
                  @dragend="onDragEnd($event, 'header')"
                  @dragleave="onDragLeave($event, 'header')"
                  @dragenter="onDragEnter($event, 'header')"
                >
                  <div
                    v-for="(item, i) in selectedModels.header"
                    :key="item.id + i"
                    draggable
                    @dragstart="moveElement($event, item, i)"
                    class="draggable"
                  >
                    <v-list-item
                      v-if="item.type !== 'placeholder'"
                      class="pa-0 border rounded-lg list-element my-2 py-0"
                      max-hight="400"
                      hight="400"
                      min="400"
                    >
                      <v-list-item-content class="py-0 pl-3">
                        <div class="d-flex">
                          <div class="d-inline mr-4 py-3">
                            <v-list-item-icon class="py-0 d-inline mx-0 px-0">
                              <v-icon
                                x-large
                                color="primary"
                                class="py-0"
                                >{{ item.icon }}</v-icon
                              >
                            </v-list-item-icon>
                          </div>
                          <div class="w-75 py-3">
                            <v-list-item-title class="text-capitalize d-inline">
                              <div>
                                <!-- make content in the same line -->
                                <v-textarea
                                  v-if="item.inputType === 'text'"
                                  @change="updateJsonForCurrentSection"
                                  v-model="item.value"
                                  dense
                                  outlined
                                  class="py-0"
                                  :label="$t(item.title)"
                                  hide-details
                                  :rules="[(v) => !!v || $t('generic.lang_requiredField')]"
                                  append-icon="mdi-variable-box"
                                  @click:append="showVariablesPicker(item)"
                                  :rows="5"
                                ></v-textarea>
                                <v-text-field
                                  v-else-if="item.inputType === 'number'"
                                  @change="updateJsonForCurrentSection"
                                  v-model="item.value"
                                  dense
                                  outlined
                                  :type="item.inputType"
                                  min="1"
                                  class="py-0"
                                  :label="$t(item.title)"
                                  hide-details
                                  :rules="[(v) => !!v || $t('generic.lang_requiredField')]"
                                ></v-text-field>
                                <v-select
                                  v-else-if="item.inputType === 'select'"
                                  v-model="item.value"
                                  :items="item.options"
                                  dense
                                  outlined
                                  hide-details
                                  @change="updateJsonForCurrentSection"
                                  class="py-0"
                                  :label="$t(item.title)"
                                  :rules="[(v) => !!v || $t('generic.lang_requiredField')]"
                                ></v-select>
                                <v-switch
                                  v-else-if="item.inputType === 'switch'"
                                  v-model="item.value"
                                  dense
                                  outlined
                                  hide-details
                                  :true-value="'true'"
                                  :false-value="'false'"
                                  @change="updateJsonForCurrentSection"
                                  class="py-0"
                                  :value="'true'"
                                  :label="$t(item.title)"
                                ></v-switch>
                                <div
                                  class="pt-3"
                                  v-else
                                >
                                  {{ $t(item.title) }}
                                </div>
                              </div>
                            </v-list-item-title>
                          </div>
                        </div>
                      </v-list-item-content>
                      <v-list-item-action class="py-0">
                        <v-btn
                          icon
                          @click="removeModelFromSection(i, 'header')"
                          class="text-capitalize"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <div
                      v-else
                      class="placeholder border border-dashed rounded-lg p-3"
                    ></div>
                  </div>
                </div>
              </v-col>

              <v-col cols="4">
                <v-textarea
                  id="headerJsonText"
                  v-model="form.tmplHeaderObj"
                  :rules="[(v) => !!v && prettyFormatHeader]"
                  dense
                  hide-details
                  readonly
                  class="border"
                  :class="[
                    this.headerJsonerror ? 'border-danger' : 'border-success',
                  ]"
                  filled
                  outlined
                  rows="20"
                  @change="prettyFormatHeader"
                  @focus="showTouchKeyboard"
                ></v-textarea>
              </v-col>
            </v-row>
          </b-tab>
          <b-tab :title="$t('settings.lang_receiptSetFooter')">
            <v-row>
              <v-col cols="8">
                <div
                  :class="[$vuetify.theme.dark ? 'dark-bg' : 'grey lighten-4']"
                  class="drop-zone rounded pa-3"
                  @drop="onDrop($event, 'footer')"
                  @dragover="onDragOver($event, 'footer')"
                  @dragend="onDragEnd($event, 'footer')"
                  @dragleave="onDragLeave($event, 'footer')"
                  @dragenter="onDragEnter($event, 'footer')"
                >
                  <div
                    v-for="(item, i) in selectedModels.footer"
                    :key="item.id + i"
                    draggable
                    @dragstart="moveElement($event, item, i)"
                  >
                    <v-list-item
                      v-if="item.type !== 'placeholder'"
                      class="pa-0 border rounded-lg list-element my-2 py-0"
                      max-hight="400"
                      hight="400"
                      min="400"
                    >
                      <v-list-item-content class="py-0 pl-3">
                        <div class="d-flex">
                          <div class="d-inline mr-4 py-3">
                            <v-list-item-icon class="py-0 d-inline mx-0 px-0">
                              <v-icon
                                x-large
                                color="primary"
                                class="py-0"
                                >{{ item.icon }}</v-icon
                              >
                            </v-list-item-icon>
                          </div>
                          <div class="w-75 py-3">
                            <v-list-item-title class="text-capitalize d-inline">
                              <div>
                                <!-- make content in the same line -->
                                <v-text-field
                                  v-if="item.inputType === 'text'"
                                  @change="updateJsonForCurrentSection"
                                  v-model="item.value"
                                  dense
                                  outlined
                                  class="py-0"
                                  :label="$t(item.title)"
                                  hide-details
                                  :rules="[(v) => !!v || $t('generic.lang_requiredField')]"
                                  append-icon="mdi-variable-box"
                                  @click:append="showVariablesPicker(item)"
                                ></v-text-field>
                                <v-text-field
                                  v-else-if="item.inputType === 'number'"
                                  @change="updateJsonForCurrentSection"
                                  v-model="item.value"
                                  dense
                                  outlined
                                  :type="item.inputType"
                                  min="1"
                                  class="py-0"
                                  :label="$t(item.title)"
                                  hide-details
                                  :rules="[(v) => !!v || $t('generic.lang_requiredField')]"
                                ></v-text-field>
                                <v-select
                                  v-else-if="item.inputType === 'select'"
                                  v-model="item.value"
                                  :items="item.options"
                                  dense
                                  outlined
                                  hide-details
                                  @change="updateJsonForCurrentSection"
                                  class="py-0"
                                  :label="$t(item.title)"
                                  :rules="[(v) => !!v || $t('generic.lang_requiredField')]"
                                ></v-select>
                                <v-switch
                                  v-else-if="item.inputType === 'switch'"
                                  v-model="item.value"
                                  dense
                                  outlined
                                  hide-details
                                  :true-value="'true'"
                                  :false-value="'false'"
                                  @change="updateJsonForCurrentSection"
                                  class="py-0"
                                  :value="'true'"
                                  :label="$t(item.title)"
                                ></v-switch>
                                <div
                                  class="pt-3"
                                  v-else
                                >
                                  {{ $t(item.title) }}
                                </div>
                              </div>
                            </v-list-item-title>
                          </div>
                        </div>
                      </v-list-item-content>
                      <v-list-item-action class="py-0">
                        <v-btn
                          icon
                          @click="removeModelFromSection(i, 'footer')"
                          class="text-capitalize"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <div
                      v-else
                      class="placeholder border border-dashed rounded-lg p-3"
                    ></div>
                  </div>
                </div>
              </v-col>

              <v-col cols="4">
                <v-textarea
                  id="headerJsonText"
                  v-model="form.tmplFooterObj"
                  :rules="[(v) => !!v && prettyFormatFooter]"
                  dense
                  hide-details
                  readonly
                  class="border"
                  :class="[
                    this.footerJsonerror ? 'border-danger' : 'border-success',
                  ]"
                  filled
                  outlined
                  rows="20"
                  @change="prettyFormatFooter"
                  @focus="showTouchKeyboard"
                ></v-textarea>
              </v-col>
            </v-row>
          </b-tab>
        </b-tabs>
      </v-col>
    </v-row>

    <!-- variables picker dialog -->
    <v-dialog
      v-if="modelToAppendVariablesTo"
      v-model="variablesDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-text class="pt-6">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="modelToAppendVariablesTo.value"
                dense
                :label="$t(modelToAppendVariablesTo.title).toUpperCase()"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-chip
                @click="appendVariable(variable)"
                v-for="(variable, i) in variables"
                :key="variable + i"
                class="ma-2"
                color="primary"
                outlined
              >
                <v-avatar left>
                  <v-icon>mdi-plus</v-icon>
                </v-avatar>
                {{ $t(variable.text) }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error darken-1"
            text
            @click="closeVariablesPicker"
          >
            {{ $t('generic.lang_cancel') }}
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="saveVariable"
          >
            {{ $t('generic.lang_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import mixin from '@/mixins/KeyboardMixIns';
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';
import randomString from 'randomstring';

export default {
  name: 'ReceiptDataComponent',
  mixins: [mixin],
  components: {
    quillEditor,
  },
  data: () => {
    return {
      modelGroups: [
        'generic.lang_common',
        'generic.lang_text',
        'generic.lang_qr_code',
        'tablebee.lang_images',
        'generic.lang_others',
      ],
      modelInDrag: null,
      modelInDragOldIdx: null,
      currentTab: 1,
      models: [
        {
          group: 'generic.lang_common',
          id: 'align',
          title: 'settings.lang_receiptChooseAlignment',
          icon: 'mdi-format-align-left',
          options: ['left', 'center', 'right'],
          isOneTimeUse: false,
          type: 'model',
          inputType: 'select',
          value: 'left',
        },
        {
          group: 'generic.lang_common',
          id: 'feed',
          title: 'generic.lang_linebreak',
          icon: 'mdi-format-line-spacing',
          isOneTimeUse: false,
          options: null,
          type: 'model',
          inputType: 'number',
          value: 1,
        },
        {
          group: 'generic.lang_text',
          id: 'font',
          title: 'settings.lang_cutsomerDisplayFontType',
          icon: 'mdi-format-font',
          options: ['A', 'B', 'C'],
          isOneTimeUse: false,
          type: 'model',
          inputType: 'select',
          value: 'A',
        },
        {
          group: 'generic.lang_text',
          id: 'text',
          title: 'generic.lang_text',
          icon: 'mdi-format-text',
          options: ['A', 'B', 'C'],
          isOneTimeUse: false,
          type: 'model',
          inputType: 'text',
          value: '',
        },
        {
          group: 'generic.lang_text',
          id: 'fontSize',
          title: 'generic.lang_size',
          icon: 'mdi-format-size',
          isOneTimeUse: false,
          type: 'model',
          options: [1, 2, 3],
          inputType: 'select',
          value: 1,
        },
        {
          group: 'generic.lang_text',
          id: 'underline',
          title: 'generic.lang_underline',
          icon: 'mdi-format-underline',
          isOneTimeUse: false,
          type: 'model',
          options: ['true', 'false'],
          inputType: 'switch',
          value: 'false',
        },
        {
          group: 'generic.lang_text',
          id: 'reverse',
          title: 'generic.lang_reverse',
          icon: 'mdi-format-color-fill',
          isOneTimeUse: false,
          type: 'model',
          options: ['true', 'false'],
          inputType: 'switch',
          value: 'false',
        },
        {
          group: 'generic.lang_qr_code',
          id: 'barcode128',
          title: 'generic.lang_barcode',
          icon: 'mdi-barcode',
          isOneTimeUse: false,
          options: null,
          type: 'model',
          inputType: 'text',
          value: '',
        },
        {
          group: 'generic.lang_qr_code',
          id: 'qrCode',
          title: 'generic.lang_qr_code',
          icon: 'mdi-qrcode',
          isOneTimeUse: false,
          options: null,
          type: 'model',
          inputType: 'text',
          value: '',
        },
        {
          group: 'tablebee.lang_images',
          id: 'printerLogo',
          title: 'accounting.lang_receiptlogo',
          icon: 'mdi-image',
          isOneTimeUse: true,
          options: null,
          type: 'model',
          value: 'true',
        },
        {
          group: 'generic.lang_others',
          id: 'cut',
          title: 'generic.lang_cut',
          icon: 'mdi-content-cut',
          isOneTimeUse: false,
          options: ['true', 'false'],
          type: 'model',
          //inputType: 'switch',
          value: 'true',
        },
        {
          group: 'generic.lang_others',
          id: 'cashDrawer',
          title: 'generic.lang_posCashDrawerOpen',
          icon: 'mdi-cash-multiple',
          isOneTimeUse: false,
          options: ['true', 'false'],
          type: 'model',
          value: 'true',
          inputType: 'switch',
        },
      ],

      selectedModels: {
        /** every model should have a random id*/
        header: [
          {
            id: 'placeholder',
            randomId: randomString.generate(10),
            type: 'placeholder',
          },
        ],
        footer: [
          {
            id: 'placeholder',
            type: 'placeholder',
            randomId: randomString.generate(10),
          },
        ],
      },
      removePlaceholderDebounce: null,
      addPlaceholderDebounce: null,
      loading: false,
      variablesDialog: false,
      variables: [
        { text: 'accounting.lang_retourOriginalID', value: '@@INVOICEID@@' },
        { text: 'generic.lang_seller_id', value: '@@SALESCLERKID@@' },
        { text: 'generic.lang_TableNo', value: '@@TABLENO@@' },
        { text: 'generic.lang_party', value: '@@PARTYNAME@@' },
        { text: 'generic.lang_cashierID', value: '@@CASHIERID@@' },
        { text: 'generic.lang_money_back', value: '@@MONEYBACK@@' },
        { text: 'generic.lang_money_given', value: '@@MONEYGIVEN@@' },
        { text: 'accounting.lang_dateOfInvoice', value: '@@INVOICEDATE@@' },
        { text: 'generic.lang_invoice_time', value: '@@INVOICETIME@@' },
        { text: 'generic.lang_sales_type', value: '@@SALESTYPE@@' },
        { text: 'generic.lang_total', value: '@@TOTAL@@' },
        { text: 'accounting.lang_PaymentMethod', value: '@@PAYMENTTYPE@@' },
      ],
      modelToAppendVariablesTo: null,
      tmplLoading: false,
      headerJsonerror: '',
      footerJsonerror: '',
      tmplHeaderObj: '',
      tmplFooterObj: '',
      form: {
        tmplHeaderObj: '',
        tmplFooterObj: '',
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    /**
     * parse models to json
     *
     * @param {Array} models
     * @returns {Array}
     */
    parseModelsToJson(models) {
      const json = [];
      models.forEach((item) => {
        json.push({
          [item.id]: item.value,
        });
      });

      return json;
    },
    updateJsonForCurrentSection() {
      if (this.currentSection == 'header') {
        this.form.tmplHeaderObj = JSON.stringify(
          this.parseModelsToJson(this.selectedModels.header),
          null,
          2
        )
          .replace(',', ',\n')
          .replace(/(?:\n)+/g, '\n');
      } else if (this.currentSection == 'footer') {
        this.form.tmplFooterObj = JSON.stringify(
          this.parseModelsToJson(this.selectedModels.footer)
        );
      }
    },
    /**
     * parse json to models
     *
     * @param {Array} json
     * @returns {Array}
     */
    parseJsonToModels(json) {
      // loop through the json and create models for each item
      // get both key and value

      const models = [];
      for (const [key, value] of Object.entries(json)) {
        const realKey = Object.keys(value)[0];
        const realValue = value[realKey];
        const model = this.models.find((item) => item.id == realKey);

        if (model) {
          models.push({
            ...model,
            value: realValue,
            randomId: randomString.generate(10),
          });
        }
      }

      return models;
    },
    showVariablesPicker(item) {
      this.variablesDialog = true;
      this.modelToAppendVariablesTo = { ...item };
    },
    appendVariable(variable) {
      this.modelToAppendVariablesTo.value += variable.value;
    },
    closeVariablesPicker() {
      this.variablesDialog = false;
      this.modelToAppendVariablesTo = null;
    },
    saveVariable() {
      this.variablesDialog = false;
      // find the model in the selected models by randomId and update it
      const index = this.selectedModels[this.currentSection].findIndex(
        (item) => item.randomId == this.modelToAppendVariablesTo.randomId
      );

      if (index > -1) {
        this.selectedModels[this.currentSection][index] = {
          ...this.modelToAppendVariablesTo,
        };
      }

      this.modelToAppendVariablesTo = null;
      this.updateJsonForCurrentSection();
    },
    removeModelFromSection(index, section) {
      // remove this index using spread operator and handle the case when the index is the last one or the first one
      if (index > -1) {
        this.selectedModels[section] = [
          ...this.selectedModels[section].slice(0, index),
          ...this.selectedModels[section].slice(index + 1),
        ];

        this.updateJsonForCurrentSection();
      }
    },
    removePlaceholder() {
      if (this.removePlaceholderDebounce)
        clearTimeout(this.removePlaceholderDebounce);

      if (this.addPlaceholderDebounce)
        clearTimeout(this.addPlaceholderDebounce);

      this.removePlaceholderDebounce = setTimeout(() => {
        const index = this.selectedModels[this.currentSection].findIndex(
          (item) => item.type == 'placeholder'
        );

        if (index > -1) {
          this.selectedModels[this.currentSection].splice(index, 1);
        }
      }, 150);
    },
    startDrag(evt, item) {
      this.modelInDrag = item;
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
    },
    moveElement(evt, item, index) {
      this.modelInDrag = item;
      this.modelInDragOldIdx = index;
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
    },
    onDrop(evt, section) {
      //this.modelInDrag = null;
      const rect = evt.target.getBoundingClientRect();

      // get the position of target element in the drop zone
      const position = Math.floor((evt.clientY - rect.top) / (rect.height / 3));

      const listItem = evt.target.closest('.list-element');

      if (listItem) {
        const rect = listItem.getBoundingClientRect();
        const isInTopHalf =
          evt.clientY >= rect.top && evt.clientY <= rect.top + rect.height / 2;

        // get the index of the target element in the drop zone
        const index = Array.from(
          listItem.parentNode.parentNode.children
        ).indexOf(listItem.parentNode);

        if (isInTopHalf) {
          this.selectedModels[section].splice(index, 0, {
            ...this.modelInDrag,
          });
        } else {
          this.selectedModels[section].splice(index + 1, 0, {
            ...this.modelInDrag,
          });
        }
        this.removePlaceholder();
        listItem.parentNode.parentNode.querySelectorAll('.placeholder').forEach((item) => item.classList.remove('placeholder'));

        if(this.modelInDragOldIdx != null) {
          if (index > this.modelInDragOldIdx) {
            this.selectedModels[section].splice(this.modelInDragOldIdx, 1);
          } else if (index < this.modelInDragOldIdx) {
            this.selectedModels[section].splice(this.modelInDragOldIdx + 1, 1);
          } else {
            this.selectedModels[section].splice(this.modelInDragOldIdx, 1);
          }
        }
      } else {
        const isInTopHalf =
          evt.clientY >= rect.top && evt.clientY <= rect.top + rect.height / 2;

        if (isInTopHalf) {
          this.selectedModels[section].splice(position, 0, {
            ...this.modelInDrag,
          });
        } else {
          // add modelInDrag to the selectedModels in the last position
          this.selectedModels[section].push({ ...this.modelInDrag });
        }
        this.removePlaceholder();

        if(this.modelInDragOldIdx != null) {
          if (position > this.modelInDragOldIdx) {
            this.selectedModels[section].splice(this.modelInDragOldIdx, 1);
          } else if (position < this.modelInDragOldIdx) {
            this.selectedModels[section].splice(this.modelInDragOldIdx + 1, 1);
          } else {
            this.selectedModels[section].splice(this.modelInDragOldIdx, 1);
          }
        }
      }

      this.modelInDragOldIdx = null;
      this.updateJsonForCurrentSection();
      // add modelInDrag to the selectedModels
    },
    createPlaceholder() {
      return {
        type: 'placeholder',
        id: Math.random(),
      };
    },
    addPlaceholder(position, section) {
      if (this.addPlaceholderDebounce)
        clearTimeout(this.addPlaceholderDebounce);

      this.addPlaceholderDebounce = setTimeout(() => {
        this.selectedModels[section].splice(
          position,
          0,
          this.createPlaceholder()
        );
      }, 170);
    },
    onDragEnd(evt, section) {
      //this.modelInDrag = null;
      this.removePlaceholder();
    },
    onDragLeave(evt, section) {
      evt.preventDefault();
      // check if the mouse is still in the drop zone
      // if not, remove the placeholder

      const rect = evt.target.getBoundingClientRect();
      const isInDropZone =
        evt.clientX >= rect.left &&
        evt.clientX <= rect.right &&
        evt.clientY >= rect.top &&
        evt.clientY <= rect.bottom;

      if (!isInDropZone) {
        this.removePlaceholder();
      }
    },
    onDragEnter(evt, section) {
      evt.preventDefault();
    },
    onDragOver(evt, section) {
      evt.preventDefault();

      // check if the target is the drop zone it self
      // if yes add a placeholder to the selectedModels after checking if there is already a placeholder, if not add one

      if (evt.target.classList.contains('drop-zone')) {
        // stop if there is no item in the list yet
        const count = evt.target.querySelectorAll('.list-element').length;
        if (count == 0) {
          return;
        }

        const index = this.selectedModels[section].findIndex(
          (item) => item.type == 'placeholder'
        );

        // check if we the placeholder should be added to the top or bottom of the drop zone
        const rect = evt.target.getBoundingClientRect();
        const isInTopHalf =
          evt.clientY >= rect.top && evt.clientY <= rect.top + rect.height / 2;

        if (index > -1) {
          if (isInTopHalf) {
            this.selectedModels[section].splice(index, 1);
            this.addPlaceholder(0, section);
          } else {
            this.selectedModels[section].splice(index, 1);
            this.addPlaceholder(this.selectedModels[section].length, section);
          }
        } else {
          if (isInTopHalf) {
            this.addPlaceholder(0, section);
          } else {
            this.addPlaceholder(this.selectedModels[section].length, section);
          }
        }
      } else {
        //check if the target is "list-element" and add a placeholder after or before the target element
        const listItem = evt.target.closest('.list-element');

        // check if listItem exists and if it has children
        if (listItem?.children?.length) {
          this.removePlaceholder();
          // remove the placeholder class from all list items
          listItem.parentNode.parentNode
            .querySelectorAll('.placeholder')
            .forEach((item) => item.classList.remove('placeholder'));

          // toggle the placeholder class on the target element
          listItem.classList.toggle('placeholder');
        } else {
          //on the placeholder
        }
      }
    },
    save() {
      //if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.form.printTmplId = this.form.id;
      this.form.updateType = 'template';
      this.axios
        .post(ENDPOINTS.PRINTSETTINGS.TEMPLATES.UPDATE, this.form)
        .then((res) => {
          if (res.data.success) {
            Events.$emit('showSnackbar', {
              message: this.$t('erp.lang_itemSuccessfulEdited'),
              color: 'success',
            });
            //this.$refs.form.reset();
            this.dialog = false;
            this.$router.push('/settings/printerSettings/receiptTmpl');
          } else if (!res.data.success) {
            Events.$emit('showSnackbar', {
              color: 'error',
              message: res.data.msg,
            });
          } else {
            Events.$emit('showSnackbar', {
              color: 'error',
              message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            color: 'error',
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$router.push('/settings/printerSettings/receiptTmpl');
    },
    getData() {
      this.tmplLoading = true;
      this.axios
        .post(ENDPOINTS.PRINTSETTINGS.TEMPLATES.GET, {
          printTmplID: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.success) {
            this.form = Object.assign({}, res.data.printerTmpl);
            this.selectedModels.header = this.parseJsonToModels(
              JSON.parse(res.data.printerTmpl.tmplHeaderObj)
            );

            this.selectedModels.footer = this.parseJsonToModels(
              JSON.parse(res.data.printerTmpl.tmplFooterObj)
            );
            this.form.tmplHeaderObj = res.data.printerTmpl.tmplHeaderObj;
            this.form.tmplFooterObj = res.data.printerTmpl.tmplFooterObj;
          } else if (!res.data.success) {
            Events.$emit('showSnackbar', {
              message: res.data.msg,
              color: 'error',
            });
          } else {
            Events.$emit('showSnackbar', {
              color: 'error',
              message:
                this.$t('generic.lang_anErrorOccurredPLSTryAgain') + res.data,
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            color: 'error',
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain') + err,
          });
        })
        .finally(() => {
          this.tmplLoading = false;
        });
    },
  },
  computed: {
    currentSection() {
      return this.currentTab === 0 ? 'header' : 'footer';
    },
    modelsByGroup() {
      return (group) => this.models.filter((item) => item.group === group);
    },
    listOne() {
      return this.items.filter((item) => item.list === 1);
    },
    listTwo() {
      return this.items.filter((item) => item.list === 2);
    },
    prettyFormatHeader: function () {
      // reset error
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.headerJsonerror = '';
      let jsonValue = '';
      try {
        // try to parse
        jsonValue = JSON.parse(this.form.tmplHeaderObj);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.tmplHeaderObj = JSON.stringify(jsonValue, null, 2)
          .replace(',', ',\n')
          .replace(/(?:\n)+/g, '\n');
        return true;
      } catch (e) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.headerJsonerror = JSON.stringify(e.message);
        var textarea = document.getElementById('headerJsonText');
        if (this.headerJsonerror.indexOf('position') > -1) {
          // highlight error position
          var positionStr = this.headerJsonerror.lastIndexOf('position') + 8;
          var posi = parseInt(
            this.headerJsonerror.substr(
              positionStr,
              this.headerJsonerror.lastIndexOf('"')
            )
          );
          if (posi >= 0) {
            textarea.setSelectionRange(posi, posi + 1);
          }
        }
        return false;
      }
    },
    prettyFormatFooter: function () {
      // reset error
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.footerJsonerror = '';
      let jsonValue = '';
      try {
        // try to parse
        jsonValue = JSON.parse(this.form.tmplFooterObj);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.tmplFooterObj = JSON.stringify(jsonValue, null, 2)
          .replace(',', ',\n')
          .replace(/(?:\n)+/g, '\n');
        return true;
      } catch (e) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.footerJsonerror = JSON.stringify(e.message);
        var textarea = document.getElementById('headerJsonText');
        if (this.footerJsonerror.indexOf('position') > -1) {
          // highlight error position
          var positionStr = this.footerJsonerror.lastIndexOf('position') + 8;
          var posi = parseInt(
            this.footerJsonerror.substr(
              positionStr,
              this.footerJsonerror.lastIndexOf('"')
            )
          );
          if (posi >= 0) {
            textarea.setSelectionRange(posi, posi + 1);
          }
        }
        return false;
      }
    },
  },
};
</script>

<style>
.drop-zone {
  margin-bottom: 10px;
  padding: 20px;
}

.model-panel-element > .v-btn__content {
  display: block !important;
}

.placeholder {
  border-radius: 5px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  border: 2px dashed #dee2e6 !important;
  background-color: #1976d249;
  transition: border-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.draggable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
</style>
