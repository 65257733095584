<template>
  <v-container fluid>
    <v-card>
      <v-card-title
        :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
        class="card-header"
      >
        {{ $t('erp.lang_editTemplate') }}
      </v-card-title>
      <v-card-text class="pa-0">
        <b-tabs content-class="mt-3">
          <b-tab active :title="this.$t('settings.lang_receiptTmpl')">
            <receipt-data-component />
          </b-tab>
          <b-tab class="capitalize" :title="this.$t('generic.lang_Options')">
            <receipt-options />
          </b-tab>
        </b-tabs>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ReceiptDataComponent from '@/components/settings/printerSettings/printManager/templates/config/ReceiptDataComponent';
import ReceiptOptions from '@/components/settings/printerSettings/printManager/templates/config/ReceiptOptions';

export default {
  name: 'TemplateEditComponent',
  components: { ReceiptDataComponent, ReceiptOptions },
};
</script>

<style scoped></style>
